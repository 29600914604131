import Helmet from "../components/Helmet/Helmet";
import { Container } from "reactstrap";
import PreferencesForm from "../components/UI/PreferencesForm";

const Form = () => {
    return (
        <Helmet title="Form">

            <Container className="my-5">
                <PreferencesForm />
            </Container>
        </Helmet>
    );
}

export default Form;
