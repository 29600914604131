import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "Pengalaman luar biasa dengan DealerHub! Layanan pelanggan yang ramah dan responsif membuat proses pembelian mobil menjadi mudah dan menyenangkan. Penawaran terbaik yang diberikan membuat saya sangat puas dengan DealerHub!
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Yakoeb Susanto</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "DealerHub memberikan pengalaman pembelian mobil yang luar biasa! Layanan pelanggan mereka sangat ramah dan responsif. Mereka membantu saya dengan proses pembelian mobil dengan lancar dan memberikan penawaran terbaik. Sangat puas dengan DealerHub!"
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Siska Brodi</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "Pelayanan profesional dan bantuan yang diberikan oleh DealerHub sangat luar biasa. Mereka membantu saya memilih mobil yang sesuai dengan kebutuhan dan anggaran saya. Proses pembelian yang cepat dan mudah membuat saya sangat senang memilih DealerHub."
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Matius Qolbi</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          "Pilihan terbaik untuk pembelian mobil adalah DealerHub. Mereka menawarkan informasi yang transparan dan kesepakatan yang jelas. Pengalaman menyenangkan dengan DealerHub membuat saya merekomendasikannya kepada semua orang."
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Brenda Halim</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonial;
