// import all images from assets/images directory
import img01 from "../all-images/cars-img/nissan-offer.png";
import img02 from "../all-images/cars-img/offer-toyota.png";
import img03 from "../all-images/cars-img/bmw-offer.png";
import img04 from "../all-images/cars-img/Hyundai-Grand-i10-Nios.jpg";
import img05 from "../all-images/cars-img/Jeep-Rubicon-Wrangler.jpg";
import img06 from "../all-images/cars-img/Subaru-BRZ.png";
import img07 from "../all-images/cars-img/Suzuki-Jimny.png";
import img08 from "../all-images/cars-img/Toyota-2000gt.jpg";

const carData = [
  {
    id: 1,
    brand: "Nissan",
    rating: 112,
    carName: "Nissan Slyphy",
    imgUrl: img01,
    model: "2022", 
    price: 450,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    inden : false,
    description:
      "Dibuat dengan desain yang modern dan aerodinamis, mobil ini menawarkan pengalaman berkendara yang luar biasa. Dengan ruang kabin yang luas, Anda dapat menikmati perjalanan yang nyaman sambil membawa keluarga dan teman-teman dengan mudah. Nissan Sylphy juga dilengkapi dengan fitur-fitur canggih seperti sistem infotainment terbaru, sistem keamanan yang lengkap, dan efisiensi bahan bakar yang mengesankan.",
  },

  {
    id: 2,
    brand: "Toyota",
    rating: 102,
    carName: "Toyota Camry 2022",
    imgUrl: img02,
    model: "2022",
    price: 450,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    inden : false,
    description:
      "Sedan yang stylish dan handal yang menawarkan pengalaman berkendara yang nyaman dan mewah. Dengan desain yang elegan dan fitur canggih, Camry pasti akan mencuri perhatian di jalan. Di bawah kap mesinnya, Camry 2022 memiliki mesin yang bertenaga yang memberikan performa yang mengesankan dan efisiensi bahan bakar yang tinggi, sehingga Anda dapat menikmati perjalanan yang mulus sambil memaksimalkan efisiensi bahan bakar. Di dalam kabin, Anda akan menemukan interior yang nyaman dengan material berkualitas tinggi dan kursi yang nyaman. Camry menyediakan ruang kaki dan ruang kepala yang luas baik untuk pengemudi maupun penumpang, memastikan semua orang dapat menikmati perjalanan dengan nyaman. Kursi yang dapat dipanaskan menambah sentuhan kemewahan, menjaga Anda tetap hangat saat cuaca dingin. ",
  },

  {
    id: 3,
    brand: "BMW",
    rating: 132,
    carName: "BMW M5",
    imgUrl: img03,
    model: "2022",
    price: 465,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    inden: false,
    description:
      "BMW M5 adalah mobil performa tinggi yang menawarkan kegembiraan mengemudi yang tak tertandingi. Ditenagai oleh mesin V8 yang bertenaga tinggi, BMW M5 menghasilkan akselerasi yang luar biasa dan kecepatan maksimum yang mengagumkan. Dalam kabin yang mewah, Anda akan menemukan kenyamanan dan teknologi terkini, seperti sistem infotainment yang canggih dan fitur-fitur keamanan yang lengkap. Desain eksterior yang sporty dan aerodinamis meningkatkan tampilan yang agresif dan menggambarkan performa yang luar biasa dari mobil ini.",
  },

  {
    id: 4,
    brand: "Hyundai",
    rating: 102,
    carName: "Hyundai Grand i10 Nios",
    imgUrl: img04,
    model: "Model-2022",
    price: 150,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    inden : true,
    description:
      "Hyundai Grand i10 Nios adalah pilihan yang sempurna bagi mereka yang mencari mobil kompak yang stylish dan fungsional. Dengan desain yang modern dan aerodinamis, mobil ini menampilkan sentuhan yang segar dan tajam di jalan. Mesin yang tangguh dan efisien memberikan performa yang handal dalam setiap perjalanan. Dalam kabin yang luas dan nyaman, Anda akan menemukan fitur-fitur mutakhir seperti sistem infotainment yang intuitif dan koneksi smartphone yang mudah.",
  },

  {
    id: 5,
    brand: "Jeep",
    rating: 94,
    carName: "Jeep Rubicon Wrangler",
    imgUrl: img05,
    model: "Model-2022",
    price: "1.500",
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic 4x4",
    inden : true,
    description:
      "Jeep Wrangler Rubicon adalah SUV legendaris yang dirancang untuk petualangan off-road yang tak terbatas. Dengan kekuatan dan ketahanan yang luar biasa, mobil ini siap menaklukkan medan paling sulit dan menawarkan pengalaman mengemudi yang mendebarkan. Dilengkapi dengan sistem 4x4 yang canggih, traksi yang superior, dan ground clearance yang tinggi, Jeep Wrangler Rubicon memungkinkan Anda menjelajahi jalur-jalur yang ekstrem dengan percaya diri. Desain ikonis dan kasar Jeep Wrangler Rubicon memberikan tampilan yang tangguh dan bergaya di mana pun Anda berada.",
  },
  {
    id: 6,
    brand: "Suzuki",
    rating: 82,
    carName: "Suzuki Jimny",
    imgUrl: img07,
    model: "Model-2021",
    price: 500,
    speed: "20kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    inden : true,
    description:
      "Suzuki Jimny adalah SUV kompak yang memiliki daya tarik yang tak tertandingi dalam segmen off-road. Dengan desain yang ikonik dan kompak, Jimny memadukan gaya retro dengan kemampuan off-road yang tangguh. Meskipun ukurannya yang kecil, mobil ini dilengkapi dengan sistem penggerak 4x4 yang kuat, ground clearance yang tinggi, dan kemampuan wading yang mengesankan.",
  }
];

export default carData;
