import "../../styles/find-car-form.css";
import { Link } from "react-router-dom";

function PreferencesForm() {
    return (
        <>
            <h1 className='font-weight-bold mb-3'>
                <strong>Find Cars that Suit You!</strong>
            </h1>

            {/* <form> */}
            <div className="form-group my-4">
                <label htmlFor="manufacturer">Manufacturer:</label>
                <select className="form-control" id="make">
                    <option>Toyota</option>
                    <option>Honda</option>
                    <option>Ford</option>
                    <option>Chevrolet</option>
                    <option>Tesla</option>
                    <option>Wuling</option>
                </select>
            </div>
            <div className="form-group my-4">
                <label htmlFor="typecar">Car Type:</label>
                <select className="form-control" id="make">
                    <option>Futuristic</option>
                    <option>Classic</option>
                    <option>Normal</option>
                    <option>Electric Type</option>
                    <option>Fuel Type</option>
                </select>
            </div>
            <div className="form-group my-4">
                <label htmlFor="category">Seat:</label>
                <select className="form-control" id="category">
                    <option>2 Seat</option>
                    <option>5 Seat</option>
                    <option>9 Seat</option>
                </select>
            </div>
            <div className="form-group my-4">
                <label htmlFor="category">Category:</label>
                <select className="form-control" id="category">
                    <option>Hatchback</option>
                    <option>SUV</option>
                    <option>Coupe</option>
                    <option>Sedan</option>
                    <option>Convertible</option>
                </select>
            </div>
            <div className="form-group my-4">
                <label htmlFor="color">Color:</label>
                <select className="form-control" id="color">
                    <option>Red</option>
                    <option>Blue</option>
                    <option>Black</option>
                    <option>White</option>
                </select>
            </div>
            <div className="form-group my-4">
                <label htmlFor="transmission">Transmission:</label>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="transmission" id="automatic" value="automatic" />
                    <label className="form-check-label" htmlFor="automatic">
                        Automatic
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="transmission" id="manual" value="manual" />
                    <label className="form-check-label" htmlFor="manual">
                        Manual
                    </label>

                </div>
            </div>

            <button className="btn find__car-btn ">
                <Link to='/home' className="text-decoration-none text-white">
                    Submit
                </Link>
            </button>
            {/* </form> */}
        </>
    );
}

export default PreferencesForm;