import masterCard from "../../assets/all-images/master-card.jpg";
import paypal from "../../assets/all-images/paypal.jpg";
import "../../styles/find-car-form.css";
import { Link } from "react-router-dom";

function PaymentForm() {
    return (
        <>
            <div className="w-100">
                <div className="row justify-content-center">
                    <div className="col-md-6 w-100">
                        <div className="card">
                            <div className="card-header card_car">
                                <h3 className="text-center text-white font-weight-bold">
                                    Payment Verification
                                </h3>
                            </div>
                            <div className="card-body">

                                <img src={masterCard} alt="" />
                                <img src={paypal} alt="" className="mx-2" />

                                <form>
                                    <div className="form-group my-4">
                                        <label for="cardNumber">Card Number</label>
                                        <input type="text" className="form-control" id="cardNumber" placeholder="Enter card number" />
                                    </div>
                                    <div className="form-group my-4">
                                        <label for="expiryDate">Expiry Date</label>
                                        <input type="text" className="form-control" id="expiryDate" placeholder="MM/YY" />
                                    </div>
                                    <div className="form-group my-4">
                                        <label for="cvv">CVV</label>
                                        <input type="text" className="form-control" id="cvv" placeholder="Enter CVV" />
                                    </div>
                                    <div className="form-group my-4">
                                        <label for="nameOnCard">Name on Card</label>
                                        <input type="text" className="form-control" id="nameOnCard" placeholder="Enter name on card" />
                                    </div>
                                    <button type="submit" className="btn find__car-btn btn-block">
                                        <Link to="/payment/success" className="text-decoration-none text-white">
                                            Submit
                                        </Link>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentForm;