import { Container, Row, Col, Button } from "reactstrap";

function PaymentSuccess() {
    return (
        <Container>
            <Row>
                <Col>
                    <div className="m-5">
                        <h2 className="text-center">
                            <strong>Payment Successful
                            </strong>
                        </h2>
                        <p className="text-center">Your payment has been successfully processed.</p>
                        <p className="text-center">Thank you for your purchase!</p>
                        <Button href="/home" className="mt-3" block>
                            Back to Homepage
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PaymentSuccess;