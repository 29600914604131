import React from "react";

const NotFound = () => {
  return (
    <div class="container my-5">
      <div className="text-center">
        <h1 className="display-4">404</h1>
        <p className="lead">Page not found</p>
        <a href="/home" className="btn btn-primary">Kembali ke Home</a>
      </div>
    </div>
  );
};

export default NotFound;
