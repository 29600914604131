import React from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";

const BookingForm = () => {
  const submitHandler = (event) => {
    event.preventDefault();
  };
  return (
    <Form onSubmit={submitHandler}>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="text" placeholder="Nama Depan" />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" placeholder="Nama Belakang" />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="email" placeholder="Email" />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="number" placeholder="Nomor Telepon" />
      </FormGroup>
      
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="text" placeholder="Kota" />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" placeholder="Alamat Detail" />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        NIK
        <input type="file" placeholder="NIK" />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        KTP
        <input type="file" placeholder="KTP" />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        NPWP
        <input type="file" placeholder="NIK" />
      </FormGroup>

      <FormGroup>
        <textarea
          rows={5}
          type="textarea"
          className="textarea"
          placeholder="Write"
        ></textarea>
      </FormGroup>

      <p className="text-secondary">
        Kebutuhan administrasi lainnya akan di sesuaikan dengan dealer yang akan menghubungi anda langsung
      </p>
    </Form>
  );
};

export default BookingForm;
