import Helmet from "../components/Helmet/Helmet";
import { Container } from "reactstrap";
import PaymentForm from "../components/UI/PaymentForm";

function PaymentVerification() {
    return (
        <Helmet>
            <Container>
                <section className="my-4">
                    <PaymentForm />
                </section>
            </Container>
        </Helmet>
    );
}

export default PaymentVerification;