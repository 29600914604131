import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BookingForm from "../components/UI/BookingForm";
import PaymentMethod from "../components/UI/PaymentMethod";

function Payment() {
    return (
        <>
            <Helmet title="Payment">
                <section>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <h1 className="mb-4">
                                    <strong>Booking Form</strong>
                                </h1>
                                <BookingForm />
                            </Col>
                            <Col lg="4" >
                                <div className="border px-4 py-4">
                                    <h3 className="mb-4">
                                        <strong>Payment Method</strong>
                                    </h3>
                                    <PaymentMethod />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Helmet>
        </>
    );
}

export default Payment;