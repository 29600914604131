import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  100% Jaminan Resmi  Dealer
                </h2>

                <p className="section__description">
                  100% Jaminan Resmi Dealer. Melalui kerja sama yang kami bangun dengan dealer mobil ternama di seluruh Indonesia, DealerHub dapat memberikan Anda fasilitas terbaik untuk memenuhi kebutuhan mobil Anda. Dengan layanan yang terpercaya dan resmi, kami menyediakan pilihan mobil berkualitas dan proses pembelian yang mudah dan aman. Dapatkan pengalaman membeli mobil yang memuaskan bersama DealerHub.
                </p>

                <p className="section__description">
                  Puaskan keinginan akan mobil impian Anda dengan DealerHub. Sebagai mitra Anda dalam perjalanan menuju mobil baru, kami menyediakan beragam pilihan mobil dari merek terkenal dengan kualitas yang terjamin. Dengan layanan pelanggan yang profesional dan penawaran terbaik, DealerHub menjadikan proses pembelian mobil menjadi mudah, transparan, dan memuaskan. Percayakan kebutuhan mobil Anda kepada DealerHub dan nikmati pengalaman berkendara yang tak terlupakan.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />

      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section> */}
    </Helmet>
  );
};

export default About;
