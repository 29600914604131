import imgtoyota from "../all-images/dealer/astra.png";
import imgdaihatsu from "../all-images/dealer/jhl.png";
import imghyundai from "../all-images/dealer/prestige.jpg";

const serviceData = [
  {
    id: 1,
    title: "",
    imgUrl:imgtoyota,
    desc: "",
  },

  {
    id: 2,
    title: "",
    imgUrl:imgdaihatsu,
    desc: "" ,
  },

  {
    id: 3,
    title: "",
    imgUrl:imghyundai,
    desc: "",
  }
];

export default serviceData;
