import "../styles/find-car-form.css";
import { Link } from "react-router-dom";

function Login() {
    return (
        <div className="container d-flex flex-column justify-content-center my-5">
            <h1 className="my-4"><b>Welcome to DealerHub!</b></h1>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" />
            </div>
            <button type="submit" className="btn btn-primary w-25 find__car-btn">
                <Link to="/home" className="text-white text-decoration-none">
                    Login
                </Link>
            </button>
        </div>
    );
}

export default Login;
